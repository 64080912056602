.accounts-textarea textarea#accounts, .accounts-placeholder {
    padding: 5px;
    font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    font-size: 0.8em;
}

textarea#accounts {
    min-height: 100px;
    font-size: 0.9em;
    width: 100%;
    resize: vertical;
    background-color: transparent;
    color: black;
}

.accounts-placeholder {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    color: var(--gray);
}

.accounts-textarea {
    position: relative;
    display: block;
    width: 100%;
}

.textarea-description {
    display: flex
}

.textarea-description .upload-csv {
    align-self: flex-end;
    padding-bottom: 0.3em;
}

li.processed div{
    text-decoration: line-through;
}

.textarea-description .caption {
    flex: 1;
}

.upload-csv-button {
    background-color: var(--gray);
    font-size: 0.5em;
}

.react-file-reader-input {
    display: contents;
}

ul.accounts div.amount {
    float: right;
}


ul.accounts {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

ul.accounts li {
    padding-bottom: 0;
}

ul.accounts li.total {
    border-top: 1px solid #000;
    font-weight: bold;
}

ul.accounts div {
    display: inline-block;
    font-size: 0.8em;
    vertical-align: top;
}

.action-buttons button {
    margin-bottom: 10px;
}

.warning-text{
    display: block; color: var(--gray); font-size: 0.6em; margin-bottom: 0.5em;
}